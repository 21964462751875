<template>
    <div>
        <Navbar page="ATA's Lições aprendidas" link="/entregaveis" nameLink="Entregáveis" link_2="/entregaveis/incidentes" nameLink_2="Gestão de Incidentes" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            
            <h1 class="text-3xl mb-5">ATA's Lições aprendidas com incidentes</h1>

            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-4 xl:col-span-4">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300 bg-white">
                        <h3 class="text-sm md:text-base">Incidente</h3>
                    </div>
                </div>
                <div class="col-span-4 xl:col-span-4">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300 bg-white">
                        <h3 class="text-sm md:text-base">Onde aconteceu</h3>
                    </div>
                </div>
                <div class="col-span-4 xl:col-span-4">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300 bg-white">
                        <h3 class="text-sm md:text-base">Opções</h3>
                    </div>
                </div>
            </div>
            <div v-for="item in incidentes" :key="item._id" class="grid grid-cols-12 md:gap-4 md:mt-3">
                <div class="col-span-4 xl:col-span-4">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100 bg-white">
                        <h3 class="text-sm md:text-base">{{ item.titulo }}</h3>
                    </div>
                </div>
                <div class="col-span-4 xl:col-span-4">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100 bg-white">
                        <h3 class="text-sm md:text-base">{{ item.tipoOnde }}</h3>
                    </div>
                </div>
                <div class="col-span-4 xl:col-span-4">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100 bg-white">
                        <a v-if="item.ataReuniaoDocumento && item.ataReuniaoDocumento.mimetype" target="_blank" rel="noreferrer" :href="`${url_api}/upload?mimetype=${item.ataReuniaoDocumento.mimetype}&filename=${item.ataReuniaoDocumento.filename}&folder=empresas`" class="my-1 text-white text-sm_2 hover:bg-blue-600 bg-blue-500 rounded-xl py-1 px-2 md:px-6 mx-1">
                            Visualizar ATA
                        </a>
                        <a v-else target="_blank" rel="noreferrer" :href="`${url_api}/v1/incidentes/ataReuniao/${item._id}?token=${$store.state.token}`" class="my-1 text-white text-sm_2 hover:bg-blue-600 bg-blue-500 rounded-xl py-1 px-2 md:px-6 mx-1">
                            Visualizar ATA DOCX
                        </a>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            incidentes: [],
        }
    },
    async beforeMount() {
        const req = await this.$http.post(`/v1/incidentes/list`, { all: true, ataReuniao: true });
        this.incidentes = req.data.data;
    },
}
</script>